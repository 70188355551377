.site-content {
  .route-events & {
    background-image: url(images/header-bg.png),
      // A workaround for this to work with `background-size`
      linear-gradient(#39171C, #150505);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto 408px;

    @include small {
      background-size: auto 568px;

      .filters {
        margin-bottom: 32px;
      }
    }

    @include mediumAndLarge {
      background-size: auto 347px;

      .filters {
        margin-bottom: 72px;
      }
    }

    @include large {
      background-size: auto 370px;
    }
  }
}

b,
strong {
  font-weight: 500;
}

/**
 * Inputs
 */
input[type='checkbox'] {
  border-radius: 0;
  border: 2px solid $charcoal;

  &:checked {
    background-color: $white;
    border: 2px solid $charcoal;

    &:before {
      top: 1px;
      left: 6px;
      border-color: $charcoal;
    }
  }
}

.radio-group .radio-item .label {
  font-weight: 500;
}

input[type='radio'] {
  &:checked {
    border-width: 2px;
    border-color: $charcoal;

    &:after {
      background-color: $charcoal;
    }
  }
}

.upsell .radio-items {
  @include small {
    display: flex;
  }

  label.radio-item.form-input.selectable.ticket-type {
    @include small {
      padding-left: 5px;
      padding-right: 5px;
      flex-grow: 1;
    }
  }
}

/**
 * Messages
 */
div.message {
  border: 0;
  border-left: 4px solid $info-color;

  &.members-banner.logged-out {
    border-left: 4px solid #d62622;
    color: $white;
    background: linear-gradient(90deg, $red 0%, #FF7A00 100%);

    a {
      color: $white;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $white;
        text-decoration: none;
      }
    }

    svg {
      background-color: transparent;
      border: 2px solid $white;
    }
  }
}

.stepper {
  .count {
    font-weight: 500;
  }

  .more,
  .less {
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      border-width: 2px;
    }
  }

  &.active {
    .count {
      background-color: $charcoal;
      color: $white;
    }
  }
}

/**
 * Ticket types and steppers
 */
.selection .select-tickets {
  .ticket-group-description-wrapper {
    h2 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 4px;
    }
  }

  .ticket-type {
    &:not(:last-child) {
      margin-bottom: 24px;
      padding-bottom: 24px;
    }

    .ticket-type-description .title {
      color: $primary-color;
    }

    .price-wrapper {
      font-weight: 500;
    }
  }
}

/**
 * Membership levels
 */
form.buy-membership {
  .membership-level .radio-item {
    .label {
      font-size: 16px;
      font-weight: 500;
      color: $primary-color;
    }

    .show-benefits {
      margin-top: 10px;
    }
  }

  .auto-renew {
    .section-header {
      .content-detail-page & {
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;
      }
    }

    .radio-items {
      display: flex;

      .radio-item {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }

        .label {
          font-size: 16px;
          color: $dark-gray;
        }
      }
    }
  }
}

/**
 * Desktop cart
 */
.aside {
  .cart-widget {
    border: 1px solid $charcoal;

    section.cart {
      > h2 {
        background-color: $orion;
        color: $white;
      }

      .content > .actions {
        padding: 0 16px 16px 16px;

        button.submit {
          @extend %primary-button;
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
  }
}

/**
 * Desktop & mobile cart
 */
section.cart {
  .expiry-timer {
    padding-bottom: 16px;
    border-bottom: $cart-divider;
    margin: 0 16px;

    div.message.expires-in {
      font-size: 14px;
      margin: 0;
    }
  }

  .content {
    .ticket {
      .ticket-date {
        font-size: 14px;
        margin-top: 2px;
      }

      .price-wrapper {
        color: $charcoal;
      }

      header {
        margin-bottom: 16px;

        h4 {
          color: $black;
        }
      }

      button.remove {
        .trash-icon {
          &:hover,
          &:focus {
            stroke: $link-hover-color;
          }
        }
      }
    }

    .apply-promo-codes {
      margin: 0 16px;

      .add-promo-button {
        padding-left: 0;
        padding-right: 0;
      }

      .add-promo-form {
        padding: 16px 0;
      }
    }

    .price-wrapper {
      color: $charcoal;
      font-weight: 500;
    }

    .total-due {
      h3,
      .price-wrapper {
        font-size: 16px;
      }

      .price-wrapper {
        font-weight: 500;
      }
    }
  }
}

.mobile-cart {
  section.cart {
    .apply-promo-codes {
      padding: 0;
    }

    .total-prices {
      padding-bottom: 16px;
    }
  }
}

/**
 * Modals
 */
.modal .modal-window {
  @include mediumAndLarge {
    border-radius: 0;
  }
}

/**
 * Event listing route
 */
.event-listing-route {
  > h1 {
    color: $white;
  }

  .filters {
    label {
      color: #fff;
    }

    .filter {
      &:not(.active) {
        @extend %secondary-button;
      }

      &.active {
        @extend %primary-button;
      }
    }
  }

  article.event.event-listing-item {
    margin-bottom: 32px;
    padding-bottom: 24px;
    border: 1px solid $charcoal;
    background-color: $white;

    @include mobileExperience {
      margin-bottom: 24px;
    }

    > *:not(figure) {
      margin-left: 24px;
      margin-right: 24px;
    }

    figure img {
      margin-bottom: 0;
    }

    h2 {
      margin-top: 0;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 500;
      margin-top: -10px;
    }
  }
}

/**
 * Event details
 */
.content-detail-page {
  .selection,
  .members-only {
    padding-top: 12px;

    .select-session label.radio-item {
      line-height: 10px;
    }
  }

  .description-wrapper .summary p:last-child {
    margin-bottom: 0;
  }

  .mobile-content-wrapper {
    padding-top: 20px;

    .message.members-banner {
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }
}

/**
 * Checkout route
 */
.mobile-checkout-heading {
  font-size: 20px;
  padding: 24px 70px;
}

.checkout-route {
  .payment,
  .purchaser-details,
  .terms-conditions {
    @include smallAndMedium {
      margin-bottom: 40px;
    }
  }
}

/**
 * Complete route
 */
.order-complete-route.complete {
  .main {
    h1,
    h2 {
      @extend %h3;
    }

    h1 {
      margin: 20px 16px 0 16px;

      span {
        @extend %h1;
        margin-bottom: 12px;
      }
    }

    code.order-number {
      @extend %h3;
      color: $black;
      margin-bottom: 0;
    }
  }
}

/**
 * Sessions
 */
.select-available-session,
.date-selector {
  .selectable-button {
    border: 2px solid $charcoal;
    color: $charcoal;

    &.available {
      @include hover {
        background-color: $charcoal;
        border-color: $charcoal;
        color: white;

        .availability-status:not(.danger) {
          color: white;
        }

        .date,
        .session-time {
          text-decoration: underline;
        }
      }
    }
  }
}
