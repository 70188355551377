/**
 Footer template:

 ```html
  <div class="footer-content">
    <img class="footer-logo" src="assets/mos/static/logo.svg" />
    <p class="footer-text">© 1996-2019 Museum of Science, Boston - All Rights reserved,</p>
  </div>
 ```
 */

.site-footer-wrapper {
  color: $white;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: $charcoal;

  .site-footer-inner {
    p {
      color: $black;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: $white;
    }
  }
}

.mobile-footer {
  button.primary {
    font-weight: 500;
  }

  .total .price-wrapper {
    font-weight: 500;
  }
}

.footer-content {
  display: flex;
  justify-content: space-between;

  @include smallAndMedium {
    display: block;
  }

  img {
    width: 239px;
  }

  p {
    @include smallAndMedium {
      border-top: 1px solid $tertiary-color;
      margin-top: 24px;
      padding-top: 24px;
    }
  }
}
