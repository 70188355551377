@import "@/styles/globals.scss";
@import '../../styles/defaults/css-properties';
@import 'sass/icons';

/**
 * Variables
 */
$red: #EE3124;
$charcoal: #231F20;
$medium-gray: #ccc;
$dark-gray: #212529;
$black: #000;
$white: white;
$carmine: #FF3951;
$orion: #0E17F6;
$suva: #F0F0F0;
$iron: #5B5956;

$tertiary-color: $suva;
$quarternary-color: #f1eb9c;

$link-color: $primary-color;
$link-hover-color: $charcoal;
$heading-color: $black;
$body-color: $charcoal;

$warning-color: #fb8728;
$warning-background-color: #fee7d4;

$success-color: #6dbf13;
$success-background-color: #e2f2d0;

$divider: 1px solid $charcoal;
$cart-divider: 1px solid $charcoal;
$selectable-height: 48px;
$datepicker-icon-color: $tertiary-color;
$datepicker-icon-color-hover: $tertiary-color;

$steppers-3-button-background-color: transparent;
$steppers-3-icon-color: $secondary-color;
$steppers-3-count-active-color: $secondary-color;
$steppers-3-border: 1px solid $secondary-color;
$steppers-3-button-background-hover-color: transparent;
$steppers-3-border-hover-color: $secondary-color;

$radio-button-border-color: $medium-gray;
$members-menu-icon-color: $primary-color;

// Defaults
@import '../../styles/defaults/index';
@import '../../styles/helpers/index';

/**
 * Placeholders
 */

%h1,
%h2,
%h3,
%h4,
%h5 {
  font-weight: 500;
  line-height: 1.2;
  color: $charcoal;
}

%h1 {
  font-size: 40px;
}

%h2 {
  font-size: 26px;
  line-height: 32px;
}

%h3 {
  font-size: 20px;
  line-height: 24px;
}

%h4 {
  font-size: 18px;
  line-height: 24px;
}

%p {
  font-size: 16px;
  line-height: 24px;
}

%label {
  font-weight: 500;
  line-height: 22px;
}

%input {
  border-color: $charcoal;
  color: $dark-gray;
}

%link {
  color: $charcoal;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: underline;
  }
}

%button {
  padding: 16px 26px;
  font-weight: 500;
  border: 2px solid transparent;
}

%primary-button {
  background-color: $charcoal;
  color: $white;

  &:hover,
  &:focus {
    background-color: $charcoal;
    color: $white;
    text-decoration: underline;
    border: 2px solid $carmine;
  }

  &[disabled] {
    background-color: $iron;
    color: $suva;
  }
}

%secondary-button {
  position: relative;
  background-color: $white;
  color: $charcoal;
  border: 2px solid $charcoal;

  &[disabled] {
    border-color: $suva;
    color: $suva;
  }
}

%secondary-button-hover,
%secondary-button-active {
  color: $white;
  border: 2px solid $charcoal;
  background-color: $charcoal;
  text-decoration: underline;
}

%back-button {
  @extend %link;

  &:before {
    content: icon('arrowRightIcon', $charcoal);
    display: inline-block;
    -webkit-mask-image: none;
    background-color: transparent;
    width: 15px;
    transform: rotateY(180deg);
    margin-right: 5px;
  }
}

// Base elements
@import 'page';
@import 'elements';
@import 'links-and-buttons';
@import 'form-inputs';
@import 'modals';
@import 'messages';
@import 'badges';

// Components
@import '_header';
@import '_footer';
@import 'filters';
@import 'actions';
@import 'cart';
@import 'selection';
@import 'steppers-3';
@import 'calendar';
@import 'identity-form';
@import 'members-menu';
@import 'legend';

// Routes
@import 'checkout';
@import 'completed';
@import 'event-list';
@import 'buy-membership';
@import 'gift-of-membership';
@import 'forgot-membership';
@import 'event';
@import 'resend-tickets';
@import 'manage-order';
@import 'home';
@import 'my-account';

// Theme
@import 'sass/styles';
@import 'sass/header';
@import 'sass/footer';

// Mixins
@include twoColumnEventsList($spacing-m);
