header.site-header {
  padding: 16px 0;
  border-bottom: 1px solid $charcoal;

  @include smallAndMedium {
    padding: 12px 0;
  }

  .site-header-inner {
    @include large {
      align-items: center;
    }
  }

  .account-links {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;

    @include mobileExperience {
      font-size: 14px;
      font-weight: 500;
    }

    @include smallAndMedium {
      a {
        display: block;
      }
    }
  }
}
